import React from 'react';
import Script from 'next/script';
import {useGoogleAnalytics} from '@/lib/hooks/useGoogleAnalytics';

type GAProps = Readonly<{
  gtmId: string;
}>;

/**
 * Adds Google Analytics
 * @param GAProps - The Google Analytics ID
 */
export function GoogleAnalytics(props: GAProps) {
  useGoogleAnalytics();
  const gtmId = props.gtmId;
  if (!gtmId) return <></>;

  return (
    <>
      <Script
        id="gtm"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(
                    {'gtm.start': new Date().getTime(),event:'gtm.js'}
                    );var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${gtmId}');`,
        }}
      />
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
          height="0"
          width="0"
          style={{display: 'none', visibility: 'hidden'}}
        />
      </noscript>
    </>
  );
}
