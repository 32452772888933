import React from 'react';
import getConfig from 'next/config';
import {ArchiveFooter} from './ArchiveFooter';
import {ArchiveStyling} from '../../common/ArchiveStyling';
import {SiteMetadata} from '../../common/SiteMetadata';
import {SearchEngineOptimization} from '@/lib/types/searchEngineOptimization';
import {ArchiveHeader} from './ArchiveHeader';
import {GoogleAnalytics} from '@/components/common/GoogleAnalytics';
import {useRouter} from 'next/router';
import {extractFirstQueryValue} from '@/lib/utils/nextjs';
import {
  WordpressFooter,
  WordpressNavigation,
} from '@/features/archiveAsAProduct';
import {AnnouncementBanner} from '@/components/homepage/AnnouncementBanner';

type ArchivePageLayoutProps = Readonly<{
  navigation?: WordpressNavigation;
  footer?: WordpressFooter;
  children: React.ReactNode;
  seo?: SearchEngineOptimization;
}>;

const nextConfig = getConfig();
const hasAnnouncementBanner =
  nextConfig.publicRuntimeConfig?.featureFlags?.enableAnnouncementBanner ===
  'true';

/**
 * Renders the header and footer that will wrap the body of the page.
 * The AnnouncementBanner now fetches its own data and determines whether to display.
 */
export function ArchivePageLayout(props: ArchivePageLayoutProps) {
  const router = useRouter();
  const archive = extractFirstQueryValue(router, 'archive');
  const gtmID = function (archive) {
    switch (archive) {
      case 'hmca':
        return 'GTM-KVKTW52';
      default:
        return null;
    }
  };

  return (
    <>
      <GoogleAnalytics gtmId={gtmID(archive)} />
      <SiteMetadata seo={props.seo} />
      <ArchiveStyling>
        <div className="body-content">
          {hasAnnouncementBanner && <AnnouncementBanner />}

          <ArchiveHeader
            upperNavigation={props.navigation?.globalNavigation}
            lowerNavigation={props.navigation?.localNavigation}
            branding={props.navigation?.branding}
          />

          <main data-testid="main" id="skipto">
            {props.children}
          </main>

          <ArchiveFooter
            logo={props.footer?.logo}
            newsletter={props.footer?.newsletter}
            socialMediaLinks={props.footer?.socialMediaLinks}
            accessibility={props.footer?.accessibility}
            privacyPolicy={props.footer?.privacyPolicy}
            additionalLinks={props.footer?.additionalLinks}
            additionalLogos={props.footer?.additionalLogos}
            textBlurb={props.footer?.textBlurb}
          />
        </div>
      </ArchiveStyling>
    </>
  );
}
