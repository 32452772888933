import {WordpressSeoSettingsDto} from '@/features/archiveAsAProduct/types/dto/seo';
import {isObject} from '@/lib/utils/validation';
import {SearchEngineOptimization} from '@/lib/types/searchEngineOptimization';

/**
 * Transforms a search engine optimization data transfer object into a standard
 * WordpressSeo object
 * @param dto - The data transfer object describing the fields for search engine
 *  optimization
 * @returns A WordpressSeo object
 */
export function transformWordpressSeo(
  dto: WordpressSeoSettingsDto | undefined
): SearchEngineOptimization {
  if (!isObject(dto) || !dto?.seo) return {}; // Returns an object with no description property

  return {
    description: dto.seo.meta_description,
  };
}
